import React, { Component } from "react";
import { MDBContainer, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBCol } from "mdbreact";
//import { Link } from 'gatsby';

class VidhyaMod extends Component {
  state = {
    activeItem: "1"
  };

  toggle = tab => e => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab
      });
    }
  };

  render() {
    return (
      <MDBContainer>
        <MDBNav className="nav-tabs mt-5">
          <MDBNavItem>
            <a href="#curiculam" active={this.state.activeItem === "1"} onClick={this.toggle("1")} role="tab" >
              <h4 className="title text-info font-weight-bold px-4 m-2">Curriculum topics</h4>
            </a>
          </MDBNavItem>
          <MDBNavItem>
            <a href="#activities" active={this.state.activeItem === "2"} onClick={this.toggle("2")} role="tab" >
              <h4 className="title text-info font-weight-bold px-4 m-2">Activities</h4>
            </a>
          </MDBNavItem>
        </MDBNav>
        <MDBTabContent activeItem={this.state.activeItem} >
          <MDBTabPane tabId="1" role="tabpanel" id="curiculam">
            <MDBCol md="6" className="m-2 p-2">
              <ul className="list-group">
                <li className="list-group-item">Yoga</li>
                <li className="list-group-item">SrimadBhagavat Gita</li>
                <li className="list-group-item">Sanskrit</li>
                <li className="list-group-item">Slokas and Bhajans</li>
                <li className="list-group-item">Epic stories: Mahabharata, Ramayana</li>
                <li className="list-group-item">SrimadBhagavatam</li>
                <li className="list-group-item">Dashavatar</li>
                <li className="list-group-item">Festivals of India</li>
                <li className="list-group-item">Hindu Culture</li>
                <li className="list-group-item">Hanuman Chalisa and Vishnu Sahasranama</li>
                <li className="list-group-item">Origins of Hinduism</li>
                <li className="list-group-item">Vedanta</li>
                <li className="list-group-item">and more....</li>
                <li className="list-group-item">Our classes are very interactive. We encourage debate/discussions on various subjects regarding Hinduism.</li>
              </ul>
            </MDBCol>
          </MDBTabPane>
          <MDBTabPane tabId="2" role="tabpanel" id="activities">
            <MDBCol md="6" className="m-2 p-2">
              <ul className="list-group">
                <li className="list-group-item list-group-item-Header"><strong>Some of other activities included are:</strong></li>
                <li className=" list-group-item">Taking part in all the festivals in the temple in the form of performances or volunteering</li>
                <li className=" list-group-item">Annual Quiz competition</li>
                <li className=" list-group-item">Learning about one great spiritual master each year</li>
                <li className=" list-group-item">Bhagavat Gita Jayanthi celebrations</li>
              </ul>
            </MDBCol>
          </MDBTabPane>
        </MDBTabContent>
      </MDBContainer>
    );
  }
}
export default VidhyaMod;