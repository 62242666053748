import React from 'react';
//import Layout from '../components/layout';
import SEO from '../components/seo';
import { MDBContainer, MDBCol } from 'mdbreact';
import Pageheader from '../Utils/pageheader';
import VidhyaMod from '../components/vidhyamod'
const Vidhya = () => {
    return (
        <>
            <SEO title="Vidhyaa|Cultural Education" />
            <MDBContainer className="mt-4">
                <h3 className="title font-weight-bolder text-secondary">VIDYALAYA - Youth Education School</h3>
                <Pageheader />
                <p className="lead text-justify">An important aspect of Hinduism is to educate our Youth about our culture and religion.
                 The Hindu temple of Eastern Iowa achieves this through our unique program, Vidyalaya.
                 The focus of this program is to help our youth understand our rich Hindu culture, our scriptures,
                  rituals and Dharma. This program aids an individual to become a better person and advance
                  in their spiritual Saadana. Parents are welcome to join along and benefit from this program.</p>

                <h4 className="title font-weight-bold orange-text" >CLASSES ARE HELD WEEKLY ON SUNDAYS FROM 10AM - 11.30AM AT THE HINDU TEMPLE.</h4>

                <p className="lead text-justify">We have three levels in our programs and curriculum is designed appropriate to the group.</p>
                <MDBCol md="6">
                    <ul className="list-group">
                        <li className=" list-group-item"><strong>The Bala Vidyalaya</strong></li>
                        <li className=" list-group-item"><strong>The Madhayma Vidyalaya</strong></li>
                        <li className=" list-group-item"><strong>Yuva Vidyalaya</strong></li>
                    </ul>
                </MDBCol>
                <VidhyaMod />
                <p className="lead">Please attend any session to see what we do and talk to our mentors about enrolling your child in the program.</p>
                <p className="lead">We invite you to join us on the journey of self-realization.</p>
                <p className="lead">
                    Contact:<br />
                    Vidyalaya Mentors.<br />
                    <strong className="font-weight-bold brown-text">vidyalaya-subscribe@googlegroups.com</strong><br></br> </p>          
            </MDBContainer>
        </>
    );
};

export default Vidhya;